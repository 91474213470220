import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext } from 'react';
import OauthContext from '../../context/OauthContext';
import DevLogin from '../DevLogin';
import OauthButton from '../OauthButton';

const LoginLogoutBlock = () => {
  const oauthContext = useContext(OauthContext);
  const data = useStaticQuery(graphql`
    query LoginEnvQuery {
      site {
        siteMetadata {
          isDevBuild
        }
      }
    }
  `);

  const renderBlock = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: `${oauthContext.loggedIn ? 'flex-end' : 'center'}`,
        }}
      >
        {data.site.siteMetadata.isDevBuild && <DevLogin />}
        {!oauthContext.loggedIn && <OauthButton type="abbott" />}
        {oauthContext.loggedIn && <OauthButton type="logout" />}
      </div>
    );
  };
  return <>{oauthContext.ready && renderBlock()}</>;
};

export default LoginLogoutBlock;
